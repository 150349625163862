import React, { useState, useEffect } from "react";
import "./Homepage.css"; // Import the CSS file

const Homepage = () => {
  const [names, setNames] = useState([]);
  const [currentName, setCurrentName] = useState("");
  const [drawnName, setDrawnName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [checkedNames, setCheckedNames] = useState([]);

  const addName = () => {
    if (currentName.trim() !== "") {
      setNames([...names, currentName]);
      setCurrentName("");
      setCheckedNames([...checkedNames, true]);
    }
  };

  const toggleNameCheck = (index) => {
    const updatedCheckedNames = [...checkedNames];
    updatedCheckedNames[index] = !updatedCheckedNames[index];
    setCheckedNames(updatedCheckedNames);
  };

  const removeName = (index) => {
    const updatedNames = [...names];
    updatedNames.splice(index, 1);
    setNames(updatedNames);

    const updatedCheckedNames = [...checkedNames];
    updatedCheckedNames.splice(index, 1);
    setCheckedNames(updatedCheckedNames);
  };

  const drawName = () => {
    const eligibleNames = names.filter((_, index) => checkedNames[index]);
    if (eligibleNames.length >= 2) {
      setIsLoading(true);
    }
  };

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        const eligibleNames = names.filter((_, index) => checkedNames[index]);
        const randomIndex = Math.floor(Math.random() * eligibleNames.length);
        const drawnName = eligibleNames[randomIndex];
        setDrawnName(drawnName);
        setIsLoading(false);
      }, 2000); // Simulating a delay of 2 seconds
    }
  }, [isLoading, names, checkedNames]);

  return (
    <div className="container">
      <h1>Name Drawer App</h1>
      <div className="input-container">
        <input
          type="text"
          value={currentName}
          onChange={(e) => setCurrentName(e.target.value)}
          placeholder="Enter a name"
        />
        <button onClick={addName}>Add</button>
      </div>
      <ul>
        {names.map((name, index) => (
          <li key={index}>
            <label>
              <input
                type="checkbox"
                checked={checkedNames[index]}
                onChange={() => toggleNameCheck(index)}
              />
              <span className="checkmark"></span>
              {name}
            </label>
            <span className="remove-icon" onClick={() => removeName(index)}>
              &times;
            </span>
          </li>
        ))}
      </ul>
      {checkedNames.filter(Boolean).length >= 2 && (
        <div className="draw-button">
          <button onClick={drawName} disabled={isLoading}>
            {isLoading ? "Drawing..." : "Draw a Name"}
          </button>
        </div>
      )}
      {drawnName && !isLoading && (
        <div className="drawn-name">
          <h2>Drawn Name:</h2>
          <p>{drawnName}</p>
        </div>
      )}
    </div>
  );
};

export default Homepage;
